import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { isMobile, isAndroid, isIOS } from 'react-device-detect';

const App = () => {
  return (
    <Router>
      <Route
        path="/payment-request/:id"
        component={Redirector}
      />
      <Route
        path="/password-reset/:token"
        component={Redirector}
      />
    </Router>
  );
}

const getRedirectLink = () => {
  if (isMobile) {
    if (isAndroid) {
      return <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
        <img src="/android-logo.jpg" alt="android-logo" style={{ width: '50%', height: '50%' }} />
        <h1>Comming soon!</h1>
        {/* <a href="https://abcpay.example.com/uploads/app-development-release.apk" download>
          <h1>Download app</h1>
        </a> */}
      </div >
      // window.location.href = "market://details?id=com.facebook.katana";
    } else if (isIOS) {
      return <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
        <img src="/android-logo.jpg" alt="android-logo" style={{ width: '50%', height: '50%' }} />
        <h1>Comming soon!</h1>
        {/* <a href="https://abcpay.example.com/uploads/app-development-release.apk" download>
          <h1>Download app</h1>
        </a> */}
      </div >
      // window.location.href = 'itms-apps://apps.apple.com/us/app/facebook/id284882215';
    }
  } else {
    return <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
      <h3>you are not on mobile</h3>
    </div >
  }
}

const Redirector = ({ match }) => getRedirectLink();

export default App;
